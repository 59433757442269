var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-asset-table-container"},[_c('h3',[_vm._v(_vm._s(_vm.$t('ALL_ASSET_LIST_TABLE_COMPONENT_' + _vm.type.toUpperCase() + '_TYPE')))]),_c('CCard',{staticClass:"all-asset-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.is_footer)?_c('td',[_vm._v(_vm._s(index + 1))]):_c('td')]}},{key:"platform",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',[_c('span',[_vm._v(_vm._s(item.platform))])]):_c('td')]}},{key:"decimals",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',[_c('span',[_vm._v(_vm._s(item.decimals))])]):_c('td')]}},{key:"price",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',{staticClass:"text-right"},[_vm._v("$"+_vm._s(_vm._f("formatNumber")(item.price,2)))]):_c('td')]}},{key:"total_addresses",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.total_addresses,0))+" ")]):_c('td')]}},{key:"total_empty_addresses",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.total_empty_addresses,0))+" ")]):_c('td')]}},{key:"total_amount",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.total_amount,0))+" ")]):_c('td')]}},{key:"total",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.total,0))+" ")]):_c('td')]}},{key:"usd_amount",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',{staticClass:"text-right"},[_vm._v("$"+_vm._s(_vm._f("formatNumber")(item.usd_amount,2)))]):_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v("$"+_vm._s(_vm._f("formatNumber")(item.usd_amount,2)))])])]}},{key:"empty_address_percent",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("percent")(item.empty_address_percent))+" ")]):_c('td')]}},{key:"usd_amount_percent",fn:function(ref){
var item = ref.item;
return [(!item.is_footer)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("percent")(item.usd_amount_percent))+" ")]):_c('td')]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }