<template>
  <div class="search-all-asset-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider name="SEARCH_ALL_ASSETS_HEADER_COMPONENT_DATE_FIELD_NAME_VALIDATE" v-slot="{ errors }" rules="required">
            <div role="group" class="form-group">
              <label class>{{ $t('SEARCH_ALL_ASSETS_HEADER_COMPONENT_DATE_LABEL') }}</label>
              <date-picker v-model="searchData.filterDate" valueType="format" type="month"></date-picker>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </ValidationProvider>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <CCol md="3" sm="12">
        <CButton class="btn btn-download-csv" @click="downloadCSV()">{{
          $t('SEARCH_ALL_ASSETS_HEADER_COMPONENT_DOWNLOAD_CSV_BUTTON')
        }}</CButton>
      </CCol>
      <div class="col-md-3 btn-disabled"></div>
      <CCol md="3" sm="12">
        <CButton class="btn btn-reset" @click="resetForm()">{{ $t('SEARCH_ALL_ASSETS_HEADER_COMPONENT_RESET_BUTTON') }}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton class="btn btn-search" @click="search()">{{ $t('SEARCH_ALL_ASSETS_HEADER_COMPONENT_SEARCH_BUTTON') }}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import _ from 'lodash';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();
const filterDate = `${year}-${_.padStart(month, 2, '0')}`;

export default {
  name: 'SearchAllAssetHeader',
  components: {
    DatePicker,
  },
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterAllAssets) || this.getDefaultValues(),
      dateFormat: 'yyyy-MM',
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    downloadCSV: {
      type: Function,
      default: null,
    },
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        filterDate: filterDate,
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();

      this.search();
    },
    async search() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterAllAssets, this.searchData);
      const searchData = {
        ...this.searchData,
      };
      this.onSearch(searchData);
    },
  },
};
</script>

<style lang="scss">
.search-all-asset-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-control-plaintext {
    color: #3a4a7f;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
  }

  .form-group {
    margin-bottom: 0.5rem;
    .col-form-label {
      width: 100%;
      text-align: right;
    }

    .btn-switch-wrapper {
      margin-top: 10px;
    }
    .survey-type {
      padding-top: 10px;
    }

    .mx-datepicker {
      display: block;
      width: 100%;
    }

    &.is-invalid {
      .mx-input {
        border: 1px solid #ff5252;
      }
    }
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
