<template>
  <div class="all-asset-table-container">
    <h3>{{ $t('ALL_ASSET_LIST_TABLE_COMPONENT_' + type.toUpperCase() + '_TYPE') }}</h3>

    <CCard class="all-asset-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{ item, index }">
          <td v-if="!item.is_footer">{{ index + 1 }}</td>
          <td v-else></td>
        </template>

        <template #platform="{ item }">
          <td v-if="!item.is_footer">
            <span>{{ item.platform }}</span>
          </td>
          <td v-else></td>
        </template>

        <template #decimals="{ item }">
          <td v-if="!item.is_footer">
            <span>{{ item.decimals }}</span>
          </td>
          <td v-else></td>
        </template>

        <template #price="{ item }">
          <td v-if="!item.is_footer" class="text-right">${{ item.price | formatNumber(2) }}</td>
          <td v-else></td>
        </template>

        <template #total_addresses="{ item }">
          <td v-if="!item.is_footer" class="text-right">
            {{ item.total_addresses | formatNumber(0) }}
          </td>
          <td v-else></td>
        </template>

        <template #total_empty_addresses="{ item }">
          <td v-if="!item.is_footer" class="text-right">
            {{ item.total_empty_addresses | formatNumber(0) }}
          </td>
          <td v-else></td>
        </template>

        <template #total_amount="{ item }">
          <td v-if="!item.is_footer" class="text-right">
            {{ item.total_amount | formatNumber(0) }}
            <!-- <StakingCurrency :value="item.total_amount" :currencySymbol="item.platform" /> -->
          </td>
          <td v-else></td>
        </template>

        <template #total="{ item }">
          <td v-if="!item.is_footer" class="text-right">
            {{ item.total | formatNumber(0) }}
          </td>
          <td v-else></td>
        </template>

        <template #usd_amount="{ item }">
          <td v-if="!item.is_footer" class="text-right">${{ item.usd_amount | formatNumber(2) }}</td>
          <td v-else class="text-right">
            <strong>${{ item.usd_amount | formatNumber(2) }}</strong>
          </td>
        </template>

        <template #empty_address_percent="{ item }">
          <td v-if="!item.is_footer" class="text-right">
            {{ item.empty_address_percent | percent }}
          </td>
          <td v-else></td>
        </template>

        <template #usd_amount_percent="{ item }">
          <td v-if="!item.is_footer" class="text-right">
            {{ item.usd_amount_percent | percent }}
          </td>
          <td v-else></td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { Constants } from '@/constants';

export default {
  name: 'AllAssetsTable',
  components: {},
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        '#',
        {
          key: 'platform',
          label: this.$t('ALL_ASSET_LIST_TABLE_COMPONENT_PLATFORM_COL'),
          _style: 'min-width: 100px; text-align: left;',
        },
        {
          key: 'price',
          label: this.$t('ALL_ASSET_LIST_TABLE_COMPONENT_PRICE_COL'),
          _style: 'min-width: 80px; text-align: right;',
        },
        {
          key: 'total_addresses',
          label: this.$t('ALL_ASSET_LIST_TABLE_COMPONENT_TOTAL_ADDRESSES_COL'),
          _style: 'min-width: 100px; text-align: right;',
        },
        {
          key: 'total_empty_addresses',
          label: this.$t('ALL_ASSET_LIST_TABLE_COMPONENT_TOTAL_EMPTY_ADDRESSES_COL'),
          _style: 'min-width: 100px; text-align: right;',
        },
        {
          key: 'total_amount',
          label: this.$t('ALL_ASSET_LIST_TABLE_COMPONENT_TOTAL_AMOUNT_COL'),
          _style: 'min-width: 100px; text-align: right;',
        },
        {
          key: 'usd_amount',
          label: this.$t('ALL_ASSET_LIST_TABLE_COMPONENT_USD_AMOUNT_COL'),
          _style: 'min-width: 100px; text-align: right;',
        },
        {
          key: 'empty_address_percent',
          label: this.$t('ALL_ASSET_LIST_TABLE_COMPONENT_EMPTY_ADDRESS_PERCENT_COL'),
          _style: 'min-width: 100px; text-align: right;',
        },
        {
          key: 'usd_amount_percent',
          label: this.$t('ALL_ASSET_LIST_TABLE_COMPONENT_USD_AMOUNT_PERCENT_COL'),
          _style: 'min-width: 100px; text-align: right;',
        },
      ],
    };
  },
  props: {
    type: {
      type: String,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.all-asset-table-container {
  > h3 {
    font-size: 1.2em;
  }

  .all-asset-list-table-card {
    // border: none;
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
    }
  }
}
</style>
