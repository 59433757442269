<template>
  <div class="all-assets-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="12" sm="12">
            <h1>{{ $t('ALL_ASSET_LIST_PAGE_TITLE') }}</h1>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchAllAssetHeader :onSearch="onSearch" :downloadCSV="downloadCSV" />

        <AllAssetsTable type="web" :items="asset.web.rows" />
        <AllAssetsTable type="mobile" :items="asset.mobile.rows" />
        <AllAssetsTable type="all" :items="asset.all.rows" />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import AllAssetsTable from '@/components/AllAssets/AllAssetsTable';
import SearchAllAssetHeader from '@/components/AllAssets/SearchAllAssetHeader';
import { endpoints, env, Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import { saveAs } from 'file-saver';
import moment from 'moment';

export default {
  name: 'AllAssetList',
  components: {
    AllAssetsTable,
    SearchAllAssetHeader,
  },
  data() {
    return {
      filterData: {},
      dateFormat: Constants.dateFormat,
      asset: {
        web: {
          rows: [],
          grand_usd_total: 0,
        },
        mobile: {
          rows: [],
          grand_usd_total: 0,
        },
        all: {
          rows: [],
          grand_usd_total: 0,
        },
      },
    };
  },
  async mounted() {
    this.isLoading = false;
  },
  methods: {
    async getAllAssets(type, data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          type,
          year: filterData.year,
          month: filterData.month,
        };
        const result = await this.$http.get(endpoints.getAllAssets, { params });
        const assetData = this.asset[type];
        assetData.rows = result.data.rows || [];
        assetData.grand_usd_total = result.data.grand_usd_total;

        if (assetData.rows.length > 0) {
          assetData.rows.push({
            is_footer: true,
            usd_amount: assetData.grand_usd_total,
          });
        }


      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ALL_ASSET_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSearch(searchData) {
      const data = searchData;
      if (!data.filterDate) {
        return;
      }

      const date = moment(data.filterDate, 'yyyy-MM');
      data.year = date.year();
      data.month = date.month() + 1;

      ['web', 'mobile', 'all'].forEach((type) => {
        this.getAllAssets(type, data);
      });
    },
    async downloadCSV() {
      const filterData = {
        ...this.filterData,
      };
      const params = {
        year: filterData.year,
        month: filterData.month,
      };

      try {
        const result = await this.$http.get(endpoints.dowloadAllAssetsCSV, { params });
        const data = result;
        const fileUrl = 'all-assets.csv';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ALL_ASSET_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.all-assets-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
